import Image from "next/image";
import { FC } from "react";
import styles from "@/pages/top/PartnerDataSyncBanner/PartnerDataSyncBanner.module.scss";
import logo from "/public/static/images/top-images/partner-companies-logo.png";

const PartnerDataSyncBanner: FC = () => (
  <div className={styles.container}>
    <div className={styles.imageContainer}>
      <Image src={logo} alt="連携企業140社以上" fill />
    </div>
    <p className={styles.text}>
      <span className={`is-pc-only ${styles.primaryText}`}>最新の空室情報</span>
      <span className={`is-sp-only ${styles.primaryText}`}>
        <span className={styles.accentPC}>最</span>
        <span className={styles.accentPC}>新</span>
        <span className={styles.isSmallSP}>の</span>空室情報
      </span>
      <span>を</span>
      <br className="is-sp-only" />
      <span>管理会社のデータベースから連携</span>
    </p>
  </div>
);
export default PartnerDataSyncBanner;
