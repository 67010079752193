import RankingTab from "./RankingTab";
import { getMunicipality } from "~/types/Models/Municipality";
import { getStation } from "~/types/Models/Station";

enum REGION_KEY {
  ALL_AREA = "all_area",
  TOKYO = "tokyo",
  KANAGAWA = "kanagawa",
}

enum TAB_KEY {
  ALL = "all",
  SINGLE = "single",
  COUPLE = "couple",
  FAMILY = "family",
}

export enum RankingType {
  AREA = "area",
  STATION = "station",
}

type AreaRankingItem = { jisX_0401_0402: string };
type StationRankingItem = { station_id: number };

type RankingListProps = {
  rankingType: RankingType;
  prefectureName?: string;
};

const regionKeyMap: Record<string, REGION_KEY> = {
  東京都: REGION_KEY.TOKYO,
  神奈川県: REGION_KEY.KANAGAWA,
};

const AREA_RANKINGS: {
  [R in REGION_KEY]: { [K in TAB_KEY]: AreaRankingItem[] };
} = {
  [REGION_KEY.ALL_AREA]: {
    [TAB_KEY.ALL]: [
      { jisX_0401_0402: "13110" }, // 目黒区
      { jisX_0401_0402: "13113" }, // 渋谷区
      { jisX_0401_0402: "13103" }, // 港区
      { jisX_0401_0402: "13112" }, // 世田谷区
      { jisX_0401_0402: "13104" }, // 新宿区
      { jisX_0401_0402: "13101" }, // 千代田区
      { jisX_0401_0402: "13102" }, // 中央区
      { jisX_0401_0402: "13109" }, // 品川区
      { jisX_0401_0402: "13105" }, // 文京区
      { jisX_0401_0402: "13114" }, // 中野区
    ],
    [TAB_KEY.SINGLE]: [
      { jisX_0401_0402: "13110" }, // 目黒区
      { jisX_0401_0402: "13113" }, // 渋谷区
      { jisX_0401_0402: "13103" }, // 港区
      { jisX_0401_0402: "13112" }, // 世田谷区
      { jisX_0401_0402: "13104" }, // 新宿区
      { jisX_0401_0402: "13102" }, // 中央区
      { jisX_0401_0402: "13109" }, // 品川区
      { jisX_0401_0402: "13101" }, // 千代田区
      { jisX_0401_0402: "13105" }, // 文京区
      { jisX_0401_0402: "13114" }, // 中野区
    ],
    [TAB_KEY.COUPLE]: [
      { jisX_0401_0402: "13110" }, // 目黒区
      { jisX_0401_0402: "13113" }, // 渋谷区
      { jisX_0401_0402: "13103" }, // 港区
      { jisX_0401_0402: "13112" }, // 世田谷区
      { jisX_0401_0402: "13101" }, // 千代田区
      { jisX_0401_0402: "13104" }, // 新宿区
      { jisX_0401_0402: "13102" }, // 中央区
      { jisX_0401_0402: "13105" }, // 文京区
      { jisX_0401_0402: "13109" }, // 品川区
      { jisX_0401_0402: "13114" }, // 中野区
    ],
    [TAB_KEY.FAMILY]: [
      { jisX_0401_0402: "13110" }, // 目黒区
      { jisX_0401_0402: "13113" }, // 渋谷区
      { jisX_0401_0402: "13103" }, // 港区
      { jisX_0401_0402: "13112" }, // 世田谷区
      { jisX_0401_0402: "13101" }, // 千代田区
      { jisX_0401_0402: "13104" }, // 新宿区
      { jisX_0401_0402: "13102" }, // 中央区
      { jisX_0401_0402: "13105" }, // 文京区
      { jisX_0401_0402: "13109" }, // 品川区
      { jisX_0401_0402: "13114" }, // 中野区
    ],
  },
  [REGION_KEY.TOKYO]: {
    [TAB_KEY.ALL]: [
      { jisX_0401_0402: "13110" }, // 目黒区
      { jisX_0401_0402: "13113" }, // 渋谷区
      { jisX_0401_0402: "13103" }, // 港区
      { jisX_0401_0402: "13112" }, // 世田谷区
      { jisX_0401_0402: "13104" }, // 新宿区
      { jisX_0401_0402: "13101" }, // 千代田区
      { jisX_0401_0402: "13102" }, // 中央区
      { jisX_0401_0402: "13109" }, // 品川区
      { jisX_0401_0402: "13105" }, // 文京区
      { jisX_0401_0402: "13114" }, // 中野区
    ],
    [TAB_KEY.SINGLE]: [
      { jisX_0401_0402: "13110" }, // 目黒区
      { jisX_0401_0402: "13113" }, // 渋谷区
      { jisX_0401_0402: "13103" }, // 港区
      { jisX_0401_0402: "13112" }, // 世田谷区
      { jisX_0401_0402: "13104" }, // 新宿区
      { jisX_0401_0402: "13102" }, // 中央区
      { jisX_0401_0402: "13109" }, // 品川区
      { jisX_0401_0402: "13101" }, // 千代田区
      { jisX_0401_0402: "13105" }, // 文京区
      { jisX_0401_0402: "13114" }, // 中野区
    ],
    [TAB_KEY.COUPLE]: [
      { jisX_0401_0402: "13110" }, // 目黒区
      { jisX_0401_0402: "13113" }, // 渋谷区
      { jisX_0401_0402: "13103" }, // 港区
      { jisX_0401_0402: "13112" }, // 世田谷区
      { jisX_0401_0402: "13101" }, // 千代田区
      { jisX_0401_0402: "13104" }, // 新宿区
      { jisX_0401_0402: "13102" }, // 中央区
      { jisX_0401_0402: "13105" }, // 文京区
      { jisX_0401_0402: "13109" }, // 品川区
      { jisX_0401_0402: "13114" }, // 中野区
    ],
    [TAB_KEY.FAMILY]: [
      { jisX_0401_0402: "13110" }, // 目黒区
      { jisX_0401_0402: "13113" }, // 渋谷区
      { jisX_0401_0402: "13103" }, // 港区
      { jisX_0401_0402: "13112" }, // 世田谷区
      { jisX_0401_0402: "13101" }, // 千代田区
      { jisX_0401_0402: "13104" }, // 新宿区
      { jisX_0401_0402: "13102" }, // 中央区
      { jisX_0401_0402: "13105" }, // 文京区
      { jisX_0401_0402: "13109" }, // 品川区
      { jisX_0401_0402: "13114" }, // 中野区
    ],
  },
  [REGION_KEY.KANAGAWA]: {
    [TAB_KEY.ALL]: [
      { jisX_0401_0402: "14104" }, // 横浜市中区
      { jisX_0401_0402: "14101" }, // 横浜市鶴見区
      { jisX_0401_0402: "14103" }, // 横浜市西区
      { jisX_0401_0402: "14102" }, // 横浜市神奈川区
      { jisX_0401_0402: "14205" }, // 藤沢市
      { jisX_0401_0402: "14204" }, // 鎌倉市
      { jisX_0401_0402: "14109" }, // 横浜市港北区
      { jisX_0401_0402: "14105" }, // 横浜市南区
      { jisX_0401_0402: "14117" }, // 横浜市青葉区
      { jisX_0401_0402: "14131" }, // 川崎市川崎区
    ],
    [TAB_KEY.SINGLE]: [
      { jisX_0401_0402: "14104" }, // 横浜市中区
      { jisX_0401_0402: "14103" }, // 横浜市西区
      { jisX_0401_0402: "14101" }, // 横浜市鶴見区
      { jisX_0401_0402: "14102" }, // 横浜市神奈川区
      { jisX_0401_0402: "14105" }, // 横浜市南区
      { jisX_0401_0402: "14109" }, // 横浜市港北区
      { jisX_0401_0402: "14204" }, // 鎌倉市
      { jisX_0401_0402: "14117" }, // 横浜市青葉区
      { jisX_0401_0402: "14131" }, // 川崎市川崎区
      { jisX_0401_0402: "14205" }, // 藤沢市
    ],
    [TAB_KEY.COUPLE]: [
      { jisX_0401_0402: "14104" }, // 横浜市中区
      { jisX_0401_0402: "14101" }, // 横浜市鶴見区
      { jisX_0401_0402: "14102" }, // 横浜市神奈川区
      { jisX_0401_0402: "14103" }, // 横浜市西区
      { jisX_0401_0402: "14205" }, // 藤沢市
      { jisX_0401_0402: "14204" }, // 鎌倉市
      { jisX_0401_0402: "14109" }, // 横浜市港北区
      { jisX_0401_0402: "14131" }, // 川崎市川崎区
      { jisX_0401_0402: "14133" }, // 川崎市中原区
      { jisX_0401_0402: "14105" }, // 横浜市南区
    ],
    [TAB_KEY.FAMILY]: [
      { jisX_0401_0402: "14205" }, // 藤沢市
      { jisX_0401_0402: "14104" }, // 横浜市中区
      { jisX_0401_0402: "14102" }, // 横浜市神奈川区
      { jisX_0401_0402: "14101" }, // 横浜市鶴見区
      { jisX_0401_0402: "14103" }, // 横浜市西区
      { jisX_0401_0402: "14204" }, // 鎌倉市
      { jisX_0401_0402: "14133" }, // 川崎市中原区
      { jisX_0401_0402: "14109" }, // 横浜市港北区
      { jisX_0401_0402: "14117" }, // 横浜市青葉区
      { jisX_0401_0402: "14131" }, // 川崎市川崎区
    ],
  },
};

const STATION_RANKINGS: {
  [R in REGION_KEY]: { [K in TAB_KEY]: StationRankingItem[] };
} = {
  [REGION_KEY.ALL_AREA]: {
    [TAB_KEY.ALL]: [
      { station_id: 225 }, // 渋谷
      { station_id: 228 }, // 新宿
      { station_id: 479 }, // 飯田橋
      { station_id: 223 }, // 目黒
      { station_id: 488 }, // 中野
      { station_id: 110 }, // 品川
      { station_id: 491 }, // 荻窪
      { station_id: 227 }, // 代々木
      { station_id: 295 }, // 武蔵小杉
      { station_id: 224 }, // 恵比寿
    ],
    [TAB_KEY.SINGLE]: [
      { station_id: 225 }, // 渋谷
      { station_id: 228 }, // 新宿
      { station_id: 479 }, // 飯田橋
      { station_id: 223 }, // 目黒
      { station_id: 491 }, // 荻窪
      { station_id: 488 }, // 中野
      { station_id: 224 }, // 恵比寿
      { station_id: 227 }, // 代々木
      { station_id: 6044 }, // 中目黒
      { station_id: 6087 }, // 代々木上原
    ],
    [TAB_KEY.COUPLE]: [
      { station_id: 225 }, // 渋谷
      { station_id: 228 }, // 新宿
      { station_id: 479 }, // 飯田橋
      { station_id: 223 }, // 目黒
      { station_id: 110 }, // 品川
      { station_id: 295 }, // 武蔵小杉
      { station_id: 488 }, // 中野
      { station_id: 491 }, // 荻窪
      { station_id: 9576 }, // 自由が丘
      { station_id: 227 }, // 代々木
    ],
    [TAB_KEY.FAMILY]: [
      { station_id: 225 }, // 渋谷
      { station_id: 228 }, // 新宿
      { station_id: 223 }, // 目黒
      { station_id: 110 }, // 品川
      { station_id: 295 }, // 武蔵小杉
      { station_id: 9576 }, // 自由が丘
      { station_id: 479 }, // 飯田橋
      { station_id: 272 }, // 大井町
      { station_id: 112 }, // 横浜
      { station_id: 6044 }, // 中目黒
    ],
  },
  [REGION_KEY.TOKYO]: {
    [TAB_KEY.ALL]: [
      { station_id: 225 }, // 渋谷
      { station_id: 228 }, // 新宿
      { station_id: 479 }, // 飯田橋
      { station_id: 223 }, // 目黒
      { station_id: 488 }, // 中野
      { station_id: 110 }, // 品川
      { station_id: 491 }, // 荻窪
      { station_id: 227 }, // 代々木
      { station_id: 224 }, // 恵比寿
      { station_id: 6044 }, // 中目黒
    ],
    [TAB_KEY.SINGLE]: [
      { station_id: 225 }, // 渋谷
      { station_id: 228 }, // 新宿
      { station_id: 479 }, // 飯田橋
      { station_id: 223 }, // 目黒
      { station_id: 491 }, // 荻窪
      { station_id: 488 }, // 中野
      { station_id: 224 }, // 恵比寿
      { station_id: 227 }, // 代々木
      { station_id: 6044 }, // 中目黒
      { station_id: 6087 }, // 代々木上原
    ],
    [TAB_KEY.COUPLE]: [
      { station_id: 225 }, // 渋谷
      { station_id: 228 }, // 新宿
      { station_id: 479 }, // 飯田橋
      { station_id: 223 }, // 目黒
      { station_id: 110 }, // 品川
      { station_id: 488 }, // 中野
      { station_id: 491 }, // 荻窪
      { station_id: 9576 }, // 自由が丘
      { station_id: 227 }, // 代々木
      { station_id: 480 }, // 市ヶ谷
    ],
    [TAB_KEY.FAMILY]: [
      { station_id: 225 }, // 渋谷
      { station_id: 228 }, // 新宿
      { station_id: 223 }, // 目黒
      { station_id: 110 }, // 品川
      { station_id: 9576 }, // 自由が丘
      { station_id: 479 }, // 飯田橋
      { station_id: 272 }, // 大井町
      { station_id: 6044 }, // 中目黒
      { station_id: 488 }, // 中野
      { station_id: 224 }, // 恵比寿
    ],
  },
  [REGION_KEY.KANAGAWA]: {
    [TAB_KEY.ALL]: [
      { station_id: 295 }, // 武蔵小杉
      { station_id: 112 }, // 横浜
      { station_id: 111 }, // 川崎
      { station_id: 9579 }, // 新丸子
      { station_id: 365 }, // 登戸
      { station_id: 9600 }, // 溝の口
      { station_id: 9525 }, // 相模大野
      { station_id: 9520 }, // 新百合ヶ丘
      { station_id: 9581 }, // 元住吉
      { station_id: 9598 }, // 二子新地
    ],
    [TAB_KEY.SINGLE]: [
      { station_id: 295 }, // 武蔵小杉
      { station_id: 112 }, // 横浜
      { station_id: 111 }, // 川崎
      { station_id: 9579 }, // 新丸子
      { station_id: 9525 }, // 相模大野
      { station_id: 365 }, // 登戸
      { station_id: 9600 }, // 溝の口
      { station_id: 9520 }, // 新百合ヶ丘
      { station_id: 9598 }, // 二子新地
      { station_id: 9581 }, // 元住吉
    ],
    [TAB_KEY.COUPLE]: [
      { station_id: 295 }, // 武蔵小杉
      { station_id: 112 }, // 横浜
      { station_id: 111 }, // 川崎
      { station_id: 9579 }, // 新丸子
      { station_id: 365 }, // 登戸
      { station_id: 9600 }, // 溝の口
      { station_id: 9525 }, // 相模大野
      { station_id: 9520 }, // 新百合ヶ丘
      { station_id: 9581 }, // 元住吉
      { station_id: 9598 }, // 二子新地
    ],
    [TAB_KEY.FAMILY]: [
      { station_id: 295 }, // 武蔵小杉
      { station_id: 112 }, // 横浜
      { station_id: 111 }, // 川崎
      { station_id: 9579 }, // 新丸子
      { station_id: 9600 }, // 溝の口
      { station_id: 365 }, // 登戸
      { station_id: 9581 }, // 元住吉
      { station_id: 9601 }, // 梶が谷
      { station_id: 9602 }, // 宮崎台
      { station_id: 9603 }, // 宮前平
    ],
  },
};

const getAreaItemData = (item: AreaRankingItem) => {
  const municipality = getMunicipality(item.jisX_0401_0402);
  return {
    display_name: municipality.displayName,
    query: municipality.query,
  };
};

const getStationItemData = (item: StationRankingItem) => {
  const station = getStation(item.station_id);
  return {
    display_name: station.displayName,
    query: station.query,
  };
};

const renderRanking = <T,>(
  rankings: Record<REGION_KEY, Record<TAB_KEY, T[]>>,
  getItemData: (item: T) => { display_name: string; query: Record<string, string> },
  label: string,
  prefectureName?: string,
) => {
  const regionKey = regionKeyMap[prefectureName] ?? REGION_KEY.ALL_AREA;

  const tabItems: { [K in TAB_KEY]: { display_name: string; query: Record<string, string> }[] } = {
    [TAB_KEY.ALL]: [],
    [TAB_KEY.SINGLE]: [],
    [TAB_KEY.COUPLE]: [],
    [TAB_KEY.FAMILY]: [],
  };

  Object.values(TAB_KEY).forEach((key) => {
    tabItems[key] = rankings[regionKey][key].map(getItemData);
  });

  const title = prefectureName ? `${prefectureName}の人気の${label}` : `関東の人気の${label}`;

  return <RankingTab title={title} tabItems={tabItems} />;
};

const RankingList = ({ rankingType, prefectureName }: RankingListProps) => {
  if (rankingType === RankingType.AREA) {
    return renderRanking<AreaRankingItem>(AREA_RANKINGS, getAreaItemData, "エリア", prefectureName);
  } else if (rankingType === RankingType.STATION) {
    return renderRanking<StationRankingItem>(
      STATION_RANKINGS,
      getStationItemData,
      "駅",
      prefectureName,
    );
  }

  throw new Error("Invalid ranking type");
};

export default RankingList;
