"use client";

import Link from "next/link";
import { useState } from "react";
import { useWindowSize } from "react-use";
import styles from "./RankingTab.module.scss";
import { MIN_PC_WIDTH } from "~/const/window";

enum TAB_KEY {
  ALL = "all",
  SINGLE = "single",
  COUPLE = "couple",
  FAMILY = "family",
}

type RankingItem = {
  display_name: string;
  query: Record<string, string>;
};

type TabItems = { [K in TAB_KEY]: RankingItem[] };

type RankingTabProps = {
  title: string;
  tabItems: TabItems;
};

type Tab = {
  key: TAB_KEY;
  label: string;
  floorPlan?: string;
  query?: Record<string, string>;
};

const tabs: Tab[] = [
  { key: TAB_KEY.ALL, label: "総合", floorPlan: "ALL" },
  { key: TAB_KEY.SINGLE, label: "一人暮らし", floorPlan: "1R-1LDK", query: { a_id: "15" } },
  { key: TAB_KEY.COUPLE, label: "二人暮らし", floorPlan: "1LDK-3LDK", query: { a_id: "16" } },
  { key: TAB_KEY.FAMILY, label: "ファミリー", floorPlan: "2LDK-", query: { a_id: "17" } },
];

const rankIcons: Record<number, string> = {
  0: "static/svgs/top/rank_1.svg",
  1: "static/svgs/top/rank_2.svg",
  2: "static/svgs/top/rank_3.svg",
};

const getRankDisplay = (index: number) => {
  if (rankIcons[index]) {
    return <img src={rankIcons[index]} alt={`Rank ${index + 1}`} />;
  }
  return <span className={styles.rankNumber}>{index + 1}</span>;
};

const DEFAULT_RANKING_ITEM_COUNT = 5;

// NOTE: SEOのために意図的に初回描画時に全リンクをレンダリングしている
const RankingTab = ({ title, tabItems }: RankingTabProps) => {
  const [selectedTab, setSelectedTab] = useState<TAB_KEY>(TAB_KEY.ALL);
  const [showAll, setShowAll] = useState(false);

  const { width: windowWidth } = useWindowSize();
  const isPC = windowWidth > MIN_PC_WIDTH;

  const handleClickTab = (key: TAB_KEY) => {
    setSelectedTab(key);
  };

  return (
    <section className={styles.container}>
      <header className={styles.title}>
        <h2>
          {title}
          <span className={styles.pickup}>をピックアップ</span>
        </h2>
      </header>

      <nav className={styles.tabsContainer}>
        {tabs.map(({ key, label, floorPlan }) => (
          <button
            key={key}
            className={`${styles.tabButton} ${selectedTab === key ? styles.active : ""}`}
            onClick={() => handleClickTab(key)}
          >
            <span className={styles.label}>{label}</span>
            {floorPlan && <span className={styles.floorPlan}>{floorPlan}</span>}
          </button>
        ))}
      </nav>

      <div className={styles.rankContainer}>
        <ul className={styles.rankList}>
          {Object.entries(tabItems).map(([tabKey, items]) =>
            items.map((item, index) => {
              const currentTab = tabs.find((tab) => tab.key === selectedTab);
              const mergedQuery = { ...item.query, ...currentTab?.query };
              const isHidden =
                tabKey !== currentTab.key ||
                (!isPC && !showAll && index >= DEFAULT_RANKING_ITEM_COUNT);

              return (
                <li
                  key={`${tabKey}-${index}`}
                  className={`${styles.rankItem} ${isHidden ? styles.hidden : ""}`}
                >
                  <div className={styles.rankNumber}>{getRankDisplay(index)}</div>
                  <Link
                    href={{
                      pathname: "/list",
                      query: mergedQuery,
                    }}
                    prefetch={false}
                    className={styles.name}
                  >
                    <span className="name">{item.display_name}</span>
                  </Link>
                  <div className={styles.arrow}></div>
                </li>
              );
            }),
          )}
        </ul>
        {!isPC && (
          <button className={styles.moreButton} onClick={() => setShowAll((prev) => !prev)}>
            {showAll ? "閉じる" : "もっと見る"}
            <div className={`${styles.arrow} ${showAll ? styles.arrowClose : ""}`}></div>
          </button>
        )}
      </div>
    </section>
  );
};

export default RankingTab;
