import RankingList, { RankingType } from "./RankingList";
import styles from "./Rankings.module.scss";

type RankingsProps = {
  prefectureName?: string;
};

const Rankings = ({ prefectureName }: RankingsProps) => {
  return (
    <div className={styles.rankingsWrapper}>
      <div className={styles.rankingsContainer}>
        <div className={styles.ranking}>
          <RankingList rankingType={RankingType.STATION} prefectureName={prefectureName} />
        </div>
        <div className={styles.ranking}>
          <RankingList rankingType={RankingType.AREA} prefectureName={prefectureName} />
        </div>
      </div>
    </div>
  );
};

export default Rankings;
